var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"my-5",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("รายชื่อผู้จำหน่าย")]),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{staticClass:"ml-10",attrs:{"prepend-inner-icon":"mdi-magnify","label":"ค้นหา ชื่อ เบอร์โทร","outlined":"","rounded":"","dense":"","single-line":"","hide-details":"","item-key":"_id"},on:{"keyup":function($event){return _vm.getData()}},model:{value:(_vm.filter.start_with),callback:function ($$v) {_vm.$set(_vm.filter, "start_with", $$v)},expression:"filter.start_with"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){(_vm.dealer_id = ''), (_vm.dialogUpdate = true)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มรายชื่อ ")],1)],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"calories","loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","item-key":"_id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function(x) { return x._id; }) .indexOf(item._id) + 1 + _vm.filter.item_per_page * (_vm.filter.page - 1))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"90%"},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('Detail',{attrs:{"id":_vm.dealer_id},on:{"onClose":function($event){_vm.dialogUpdate = false},"onSave":function () {
          _vm.dialogUpdate = false;
          _vm.onChangeitemsPerPage(_vm.filter.item_per_page);
        }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }